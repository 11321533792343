body {
    background-color: #1f1f1f;
}

label.form-label {
    color: white;
}

.form-wrapper {
    max-width: 500px;
    margin: 0 auto;
}

.light-text {
    color: #ffffff; /* Set your desired text color for light text */
}
  
  
  
  
/* CareersPage.css */

.careers-page {
    background-color: #1f1f1f;
    color: #fff;
    padding: 80px;
}
  
.careerpage-header {
    text-align: center;
    margin-bottom: 80px;
}
  
.careerpage-job-listings {
    display: flex;
    justify-content: space-around;
}
  
.careerpage-job-card {
    background-color: #333;
    color: #fff;
    padding: 80px;
    border-radius: 20px;
    margin: 20px;
    text-align: center;
    display: 'flex'; 
    justify-content: 'center';
}
  
.careerpage-job-card h2 {
    margin-bottom: 10px;
}
  
.careerpage-button {
    background-color: #4CAF50;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
}
  
.careerpage-button:hover {
    background-color: #45a049;
}
  
.footer {
    margin-top: 40px;
    text-align: center;
}

.social-media-icons {
    display: flex;
    margin-top: 20px;
    gap: 10px;
    justify-content: center;
  }
  
.social-media-icons a {
    color: #333;
    margin-right: 10px;
    text-decoration: none;
}

.social-media-icons a:hover {
    color: #007bff;
}
  